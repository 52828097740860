<script setup lang="ts"></script>

<template>
  <svg viewBox="0 0 341 194" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M170.36 193.2C125.27 193.2 82.81 183.4 50.81 165.59C18.04 147.36 0 122.86 0 96.6C0 70.34 18.04 45.83 50.81 27.61C82.81 9.8 125.26 0 170.36 0C215.46 0 257.91 9.8 289.91 27.61C322.67 45.84 340.72 70.34 340.72 96.6C340.72 122.86 322.68 147.36 289.91 165.59C257.91 183.39 215.45 193.2 170.36 193.2ZM170.36 8.23C126.64 8.23 85.61 17.67 54.81 34.8C24.77 51.51 8.23 73.45 8.23 96.6C8.23 119.75 24.77 141.69 54.81 158.4C85.61 175.53 126.64 184.97 170.36 184.97C214.08 184.97 255.11 175.53 285.91 158.4C315.95 141.69 332.49 119.74 332.49 96.6C332.49 73.46 315.95 51.51 285.91 34.8C255.11 17.67 214.08 8.23 170.36 8.23Z"
        fill="currentColor"
      />
      <path
        d="M74.07 112.04C69.87 105.06 68.35 96.4099 69.78 87.6799C70.68 82.2099 72.66 77.2399 75.44 73.0299L73.19 57.1599C72.55 52.6699 68.86 49.5399 64.27 50.1899C59.68 50.8399 57 54.8799 57.64 59.3699L60.25 77.8099C57.14 75.9099 53.45 74.2999 46.77 75.2399C33.21 77.1599 24.51 91.0999 26.72 106.76C28.94 122.41 41.17 133.39 54.73 131.47C60.61 130.64 64.36 128.38 67.24 123.6C68.68 125.84 71.44 128.09 75.22 127.55C79.02 127.01 81.5 124.15 81.89 120.63C78.82 118.41 76.16 115.52 74.07 112.04ZM54.8 116.2C47.92 117.17 43.29 111.73 42.27 104.55C41.25 97.3699 44.2 90.8599 51.07 89.8799C57.95 88.9099 62.58 94.3499 63.6 101.53C64.62 108.71 61.67 115.23 54.8 116.2Z"
        fill="currentColor"
      />
      <path
        d="M230.73 64.68C230.99 59.3 226.74 56.6799 223.44 52.7999C226.66 52.3899 228.7 47.49 230.53 48.02C232.36 48.55 236.01 54.1099 233.99 61.6699C238.74 51.7299 238.65 55.44 244.77 47.95C246.26 51.15 246.32 54.7299 246.02 57.9599C245.39 62.9899 239.34 65.5999 235.4 65.3099L230.73 64.68Z"
        fill="currentColor"
      />
      <path
        d="M269.78 112.51L266.07 78.1001C265.54 73.5301 265.16 69.5301 265.68 67.7901C267 63.3501 271.35 61.8101 275.11 62.9301C278.78 64.0201 280.81 66.7301 281.13 70.9301L283.16 96.5401L297.66 78.5901C300.97 74.5301 304.16 71.6001 308.31 72.8401C312.94 74.2201 315.14 78.1301 313.75 82.7701C313.09 84.9901 311.25 87.2801 309.96 88.7801L272.15 134.14C264.6 143.03 255.02 144.5 250.87 140.38C246.87 136.4 247.44 132.89 248.21 130.39C248.93 128.05 251.47 125.79 254.22 126.13C255.87 126.05 257.89 125.77 261.38 122.32L269.76 112.53L269.78 112.51Z"
        fill="currentColor"
      />
      <path
        d="M120.27 88.3301C119.94 84.8501 120.88 81.4901 122.93 78.8601C124.67 76.6201 127.09 75.0801 129.86 74.4001C129.1 71.5401 126.86 69.3001 123.6 68.7701C119.83 68.1501 117.02 70.3401 115.53 72.5501C112.75 67.7101 109.06 65.3601 103.2 64.4001C89.69 62.1801 77.21 72.8901 74.65 88.4801C72.09 104.08 80.48 118.21 93.99 120.43C99.85 121.39 104.1 120.36 108.28 116.66C108.98 119.22 110.94 122.2 114.72 122.82C118.47 123.44 121.67 121.49 123.11 118.31L120.28 88.3301H120.27ZM112.29 93.6101C111.12 100.76 106.36 106.1 99.51 104.98C92.65 103.85 89.86 97.2701 91.03 90.1201C92.2 82.9701 96.96 77.6301 103.81 78.7501C110.67 79.8801 113.46 86.4601 112.29 93.6101Z"
        fill="currentColor"
      />
      <path
        d="M174.61 92.6C174.61 92.51 174.58 92.43 174.57 92.35C169.26 91.59 165.63 87.95 164.73 82.28C164.46 80.57 164.48 78.98 164.76 77.51C161.46 76.07 157.56 75.6 153.42 75.99C146.7 76.62 142.69 79.13 140.56 83.27C139.34 81.06 136.48 78.6 132.27 79C127.66 79.44 124.79 83.35 125.22 87.86L128.84 126.15C129.27 130.66 132.81 133.97 137.43 133.53C142.04 133.09 144.91 129.18 144.48 124.67L142.14 99.91C141.63 94.5 144.46 90.18 149.87 89.67C155.28 89.16 158.87 92.86 159.38 98.28L161.72 123.04C162.15 127.55 165.7 130.86 170.31 130.42C174.92 129.98 177.79 126.07 177.36 121.56L174.62 92.59L174.61 92.6Z"
        fill="currentColor"
      />
      <path
        d="M200.79 113.1C201.36 115.29 203.64 117.35 206.08 116.71C210.96 115.44 213.51 117.75 214.51 122.12C215.51 126.49 212.34 130.94 207.43 132.06H207.17C197.42 134.61 187.31 128.96 186.01 120.46C184.71 111.96 180.72 87.05 180.72 87.05L178.93 87.34C173.96 88.14 170.43 86.46 169.63 81.49C168.83 76.52 171.66 73.82 176.64 73.02L178.43 72.73L176.8 62.59C176.08 58.12 178.69 54.03 183.26 53.29C187.83 52.56 191.59 55.63 192.31 60.1L193.94 70.24L198.21 69.55C203.18 68.75 206.71 70.43 207.51 75.4C208.31 80.37 205.48 83.07 200.5 83.86L196.23 84.55L200.66 112.47L200.78 113.08L200.79 113.1Z"
        fill="currentColor"
      />
      <path
        d="M243.28 67.6999C233.99 71.0099 231.85 70.1199 229.34 68.7299C222.52 64.9499 218.25 69.4199 217.3 73.4699C217.91 73.7499 218.47 74.1099 219.01 74.4999C219.32 74.3999 219.64 74.3399 219.97 74.3499C221.7 74.3699 223.09 75.7899 223.07 77.5299C223.07 78.0699 222.91 78.5699 222.66 79.0199C222.77 79.2599 222.85 79.5099 222.94 79.7599C224.27 80.1399 225.25 81.3599 225.23 82.8099C225.21 83.9699 224.56 84.9699 223.61 85.4999C223.58 85.7099 223.55 85.9199 223.51 86.1299C224.27 86.7099 224.76 87.6199 224.75 88.6499C224.73 90.3799 223.3 91.7699 221.57 91.7499C221.35 91.7499 221.13 91.7199 220.92 91.6699C220.8 91.8099 220.69 91.9499 220.56 92.0799C220.62 92.3199 220.65 92.5699 220.65 92.8299C220.63 94.5599 219.21 95.9499 217.47 95.9299C216.67 95.9299 215.96 95.6099 215.41 95.1099C215.35 95.1199 215.29 95.1399 215.23 95.1599C214.7 96.1899 213.64 96.8899 212.4 96.8799C211.01 96.8599 209.85 95.9299 209.46 94.6699C209.17 94.5499 208.89 94.4299 208.62 94.2899C208.49 95.1299 208.42 95.7199 208.38 95.8999C204.47 117.44 226.04 119.34 228.46 119.37C230.94 119.2 232.48 118.17 234.92 119.45C237.36 120.74 238.35 121.57 240.54 121.74C247.03 123.09 262.53 114.3 263.42 92.9199C264.65 63.1999 243.27 67.6899 243.27 67.6899L243.28 67.6999ZM250.84 81.6799C248.19 83.8699 246.38 76.3999 248.18 75.8699C252.03 74.9099 252.89 79.8899 250.84 81.6799Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="341" height="194" />
      </clipPath>
    </defs>
  </svg>
</template>
